import { PagingEntry } from "@/entry/PagingEntry";

export class MuseumDetailEntry {
    // 박물관 상세 데이터
    data = new Object();

    // 소장품 리스트
    list = new Array();

    paging = new PagingEntry();
    // 박물관 Tab Index
    tabIndex = 1;

    constructor() {

    }

}