import { FETCH_PROPERTY } from "@/store/action.type";
import { SET_PROPERTY } from "@/store/mutations.type";
import { PROPERTY } from "@/store/getter.type";


import {
  PropertyService,
  CheckApi
} from "@/network/api.service";


const state = {
  data: new Object()
}

const mutations = {
  [SET_PROPERTY](state, payload) {
    // console.log("프로퍼티 업데이트");
    if (!('hold_loc_kind' in state.data)) {
      state.data = payload;
    } else {
      Object.assign(state.data, payload)
    }
  }
}

const actions = {
  async [FETCH_PROPERTY]({ state, context, commit }) {
    if (('hold_loc_kind' in state.data)) {
      // console.log("프로퍼티 존재함");
      return;
    }
    return new Promise((resolve, reject) => {
      PropertyService.get().then(res => {
        // console.log("프로퍼티 가져오기성공");
        if (!CheckApi.check(res)) {
          reject(res.data);
          return;
        }

        commit(SET_PROPERTY, res.data);
        resolve(res)
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },

}

const getters = {
  [PROPERTY](state, getters) {
    return state.data;
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}