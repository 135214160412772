
import { MuseumEntry } from "@/entry/MuseumEntry";
import { MuseumListEntry } from "@/entry/MuseumListEntry";
import { MuseumDetailEntry } from "@/entry/MuseumDetailEntry";
import { MuseumSearchEntry } from "@/entry/MuseumSearchEntry";

import {
  DATA_RESET,
  FETCH_MUSEUM_MAIN_LOCATION,
  FETCH_MUSEUM_MAIN_AGE,
  FETCH_MUSEUM_LIST,
  FETCH_MUSEUM_DETAIL,
  FETCH_MUSEUM_DETAIL_CONTENT,
  FETCH_MUSEUM_SEARCH_LIST
} from "@/store/action.type";

import {
  RESET_MESEUM,
  SET_MUSEUM_MAIN_LOCATION,
  SET_MUSEUM_MAIN_AGE,
  SET_MUSEUM_MAIN_TAB,
  SET_MUSEUM_LOCATION_TAB,
  SET_MUSEUM_LOCATION_RESPONSE,
  SET_MUSEUM_LOCATION_NEXTPAGE,
  PUSH_MUSEUM_LOCATION_LIST,
  RESET_MUSEUM_LOCATION_LIST,
  SET_MUSEUM_DETAIL_TAB,
  SET_MUSEUM_DETAIL,
  SET_MUSEUM_DETAIL_CONTENT_RESPONSE,
  SET_MUSEUM_DETAIL_CONTENT_NEXTPAGE,
  PUSH_MUSEUM_DETAIL_CONTENT_LIST,
  RESET_MUSEUM_DETAIL,
  SET_MUSEUM_SEARCH_KWD,
  RESET_MUSEUM_SEARCH,
  SET_MUSEUM_SEARCH_RESPONSE,
  SET_MUSEUM_SEARCH_NEXTPAGE,
  PUSH_MUSEUM_SEARCH_LIST,
  RESET_MUSEUM_SEARCH_LIST
} from "@/store/mutations.type";

import {
  MUSEUM_MAIN_LOCATION,
  MUSEUM_MAIN_AGE,
  MUSEUM_MAIN_SELECT_TAB,
  MUSEUM_LIST,
  MUSEUM_LIST_SELECT_TAB,
  MUSEUM_DETAIL_SELECT_TAB,
  MUSEUM_DETAIL_LIST,
  MUSEUM_DETAIL,
  MUSEUM_SEARCH_LIST,
  MUSEUM_SEARCH_KEYWORD
} from "@/store/getter.type";

import {
  MuseumService,
  CheckApi
} from "@/network/api.service";

const state = {
  museum: new MuseumEntry(),

  // 지역별 박물관 페이지 리스트
  museum_list: new MuseumListEntry(),

  // 박물관 상세 
  mesuem_detail: new MuseumDetailEntry(),

  // 박물관 검색 
  mesuem_search: new MuseumSearchEntry()

}

const mutations = {
  // 초기화
  [RESET_MESEUM](state) {
    const reset_state = {
      museum_list: new MuseumListEntry(),
    }
    Object.assign(state, reset_state)
  },

  // 박물관 메인 지역별
  [SET_MUSEUM_MAIN_LOCATION](state, payload) {
    state.museum.list_location = payload;
  },
  // 박물관 메인 시대별
  [SET_MUSEUM_MAIN_AGE](state, payload) {
    state.museum.list_age = payload;
  },
  // 박물관 메인 텝
  [SET_MUSEUM_MAIN_TAB](state, payload) {
    state.museum.tabIndex = payload;
  },

  // 지역별 박물관 리스트

  [SET_MUSEUM_LOCATION_TAB](state, payload) {
    state.museum_list.tabIndex = payload;
  },
  [SET_MUSEUM_LOCATION_NEXTPAGE](state, payload) {
    state.museum_list.paging.nextPageNum = payload;
  },
  [SET_MUSEUM_LOCATION_RESPONSE](state, payload) {
    if (state.museum_list.paging.lastResponse === null) {
      state.museum_list.paging.lastResponse = payload;
    } else {
      Object.assign(state.museum_list.paging.lastResponse, payload)
    }
  },
  [PUSH_MUSEUM_LOCATION_LIST](state, payload) {
    state.museum_list.list = state.museum_list.list.concat(payload);
  },
  [RESET_MUSEUM_LOCATION_LIST](state) {
    // 리플레쉬 할경우에 리스트 데이터 초기화
    state.museum_list.list = new Array();
    state.museum_list.paging.lastResponse = null;
    state.museum_list.paging.nextPageNum = 1;
  },
  [SET_MUSEUM_DETAIL_TAB](state, payload) {
    // 박물관 상세 탭 인덱스
    state.mesuem_detail.tabIndex = payload;
  },
  [SET_MUSEUM_DETAIL](state, payload) {
    // 박물관 상세 정보
    state.mesuem_detail.data = payload;
  },

  [SET_MUSEUM_DETAIL_CONTENT_NEXTPAGE](state, payload) {
    // 박물관 상세 컨텐츠 정보
    state.mesuem_detail.paging.nextPageNum = payload;
  },
  [SET_MUSEUM_DETAIL_CONTENT_RESPONSE](state, payload) {
    if (state.mesuem_detail.paging.lastResponse === null) {
      state.mesuem_detail.paging.lastResponse = payload;
    } else {
      Object.assign(state.mesuem_detail.paging.lastResponse, payload)
    }
  },
  [PUSH_MUSEUM_DETAIL_CONTENT_LIST](state, payload) {
    state.mesuem_detail.list = state.mesuem_detail.list.concat(payload);
    // console.log("PUSH_MUSEUM_DETAIL_CONTENT_LIST");
    // console.log(state.mesuem_detail.list);
  },
  [RESET_MUSEUM_DETAIL](state) {
    // 리플레쉬 할경우에 리스트 데이터 초기화
    state.mesuem_detail.data = new Object();
    state.mesuem_detail.list = new Array();
    state.mesuem_detail.tabIndex = 1;
    state.mesuem_detail.paging.lastResponse = null;
    state.mesuem_detail.paging.nextPageNum = 1;
  },

  // 박물관 검색 초기화
  [RESET_MUSEUM_SEARCH](state) {
    state.mesuem_search = new MuseumSearchEntry();
  },
  [RESET_MUSEUM_SEARCH_LIST](state) {
    // 리플레쉬 할경우에 리스트 데이터 초기화
    state.mesuem_search.list = new Array();
    state.mesuem_search.paging.lastResponse = null;
    state.mesuem_search.paging.nextPageNum = 1;
  },
  // 박물관 검색 키워드
  [SET_MUSEUM_SEARCH_KWD](state, payload) {
    state.mesuem_search.keyword = payload;
  },
  // 박물관 검색 리스트
  [PUSH_MUSEUM_SEARCH_LIST](state, payload) {
    state.mesuem_search.list = payload;
  },
  // 박물관 검색 결과값
  [SET_MUSEUM_SEARCH_RESPONSE](state, payload) {
    state.mesuem_search.paging.lastResponse = payload;
  },
  // 박물관 검색 페이징
  [SET_MUSEUM_SEARCH_NEXTPAGE](state, payload) {
    state.mesuem_search.paging.nextPageNum = payload;
  }
}

const actions = {
  [DATA_RESET]: {
    root: true,
    handler({ commit }) {
      commit(RESET_MESEUM);
    }
  },
  [FETCH_MUSEUM_MAIN_LOCATION]({ commit, state }) {
    if (state.museum.list_location && state.museum.list_location.length > 0)
      return;

    return new Promise((resolve, reject) => {
      // 지역별 박물관 합계
      MuseumService.getLocationSummaryList().then(res => {

        if (!CheckApi.check(res)) {
          reject(res.data);
          return;
        }

        commit(SET_MUSEUM_MAIN_LOCATION, res.data.summary_count_list);

        resolve(res.data)
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  [FETCH_MUSEUM_MAIN_AGE]({ commit, state }) {
    if (state.museum.list_age && state.museum.list_age.length > 0)
      return;

    return new Promise((resolve, reject) => {
      // 시대별 박물관 합계
      MuseumService.getAgeSummaryList().then(res => {

        if (!CheckApi.check(res)) {
          reject(res.data);
          return;
        }

        commit(SET_MUSEUM_MAIN_AGE, res.data.summary_count_list);

        resolve(res.data)
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  [FETCH_MUSEUM_LIST]({ commit, state }, params) {

    // 지역별 박물관 리스트
    return new Promise((resolve, reject) => {
      MuseumService.getLocationList(params.area, params.kwd, params.museum_tp, state.museum_list.paging.nextPageNum).then(res => {

        if (!CheckApi.check(res)) {
          reject(res.data);
          return;
        }

        if (res.data.hold_loc_list) {
          commit(SET_MUSEUM_LOCATION_RESPONSE, res.data);
          commit(PUSH_MUSEUM_LOCATION_LIST, res.data.hold_loc_list);
          commit(SET_MUSEUM_LOCATION_NEXTPAGE, state.museum_list.paging.nextPageNum + 1);
        }

        resolve(res.data)
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  [FETCH_MUSEUM_DETAIL_CONTENT]({ commit, state }, params) {
    // 박물관 상세 컨텐츠 리스트
    return new Promise((resolve, reject) => {
      MuseumService.getContentList(params.hold_loc_id, params.age_cd, params.kwd, state.mesuem_detail.paging.nextPageNum).then(res => {

        if (!CheckApi.check(res)) {
          reject(res.data);
          return;
        }

        if (res.data.contents3dmodel_list) {
          commit(SET_MUSEUM_DETAIL_CONTENT_RESPONSE, res.data);
          commit(PUSH_MUSEUM_DETAIL_CONTENT_LIST, res.data.contents3dmodel_list);
          commit(SET_MUSEUM_DETAIL_CONTENT_NEXTPAGE, state.mesuem_detail.paging.nextPageNum + 1);
        } else {
          console.log(res.data);
        }

        resolve(res.data)
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },
  [FETCH_MUSEUM_DETAIL]({ commit, state }, params) {
    // 박물관 상세
    return new Promise((resolve, reject) => {
      MuseumService.getDetail(params.hold_loc_id, params.age_cd).then(res => {

        if (!CheckApi.check(res)) {
          reject(res.data);
          return;
        }

        if (res.data.hold_loc_list.length == 0) {
          reject(res.data);
          return;
        }

        commit(SET_MUSEUM_DETAIL, res.data.hold_loc_list[0]);
        resolve(res.data)
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },

  [FETCH_MUSEUM_SEARCH_LIST]({ commit, state }, params) {

    // 박물관 검색 리스트
    return new Promise((resolve, reject) => {
      MuseumService.getLocationList(params.area, params.kwd, null, state.mesuem_search.paging.nextPageNum).then(res => {

        if (!CheckApi.check(res)) {
          reject(res.data);
          return;
        }

        if (res.data.hold_loc_list) {
          commit(SET_MUSEUM_SEARCH_RESPONSE, res.data);
          commit(PUSH_MUSEUM_SEARCH_LIST, res.data.hold_loc_list);
          commit(SET_MUSEUM_SEARCH_NEXTPAGE, state.mesuem_search.paging.nextPageNum + 1);
        }

        resolve(res.data)
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }
}
const getters = {
  [MUSEUM_MAIN_LOCATION](state, getters) {
    return state.museum.list_location;
  },
  [MUSEUM_MAIN_AGE](state, getters) {
    return state.museum.list_age;
  },
  [MUSEUM_MAIN_SELECT_TAB](state, getters) {
    return state.museum.tabIndex;
  },
  [MUSEUM_LIST](state, getters) {
    return state.museum_list.list;
  },
  [MUSEUM_LIST_SELECT_TAB](state, getters) {
    return state.museum_list.tabIndex;
  },
  [MUSEUM_DETAIL_LIST](state, getters) {
    return state.mesuem_detail.list;
  },
  [MUSEUM_DETAIL_SELECT_TAB](state, getters) {
    return state.mesuem_detail.tabIndex;
  },
  [MUSEUM_DETAIL](state, getters) {
    return state.mesuem_detail.data;
  },
  [MUSEUM_SEARCH_LIST](state, getters) {
    return state.mesuem_search.list;
  },
  [MUSEUM_SEARCH_KEYWORD](state, getters) {
    return state.mesuem_search.keyword;
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}