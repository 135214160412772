import { FETCH_LOGIN_CHECK } from "@/store/action.type";
import { SET_LOGIN_USER, SET_LOGOUT } from "@/store/mutations.type";
import { LOGIN_USER } from "@/store/getter.type";
import JwtService from "@/network/jwt.service";

import {
  LoginService,
  CheckApi
} from "@/network/api.service";


const state = {
  data: new Object(),
}

const mutations = {
  [SET_LOGIN_USER](state, payload) {
    if (!state.data.check) {
      state.data = payload;
    } else {
      Object.assign(state.data, payload)
    }
  },
  [SET_LOGOUT](state, payload) {
    JwtService.destroyToken();
    state.data = { check: true }
  },
}

const actions = {
  async [FETCH_LOGIN_CHECK]({ state, context, commit }, params) {

    if (!params.check) {
      if (state.data.check) {
        return;
      }
    }
    return new Promise((resolve, reject) => {
      LoginService.check().then(res => {
        // console.log("로그인 가져오기성공");
        if (!CheckApi.check(res)) {
          commit(SET_LOGOUT);
          reject(res.data);
          return;
        }

        if (res.data.token_uuid) {
          JwtService.saveToken(res.data.token_uuid);
        }

        commit(SET_LOGIN_USER, {
          check: true,
          uid: res.data.user_uid,
          login_id: res.data.p_login_id,
          photo: res.data.my_photo,
          name: res.data.p_name,
          phone: res.data.phone_num,
        });
        resolve(res)
      }).catch(error => {
        if (error) {
          console.log(error);
        }
        reject(error);
      });
    });
  },

}

const getters = {
  [LOGIN_USER](state, getters) {
    return state.data;
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}