import { PagingEntry } from "@/entry/PagingEntry";

export class MuseumListEntry {
    list = new Array();
    paging = new PagingEntry();
    tabIndex = 1;

    constructor() {

    }

}