export class PagingEntry {
    list = new Array();
    // 다음 요청시 요청해야 하는 페이지 번호
    nextPageNum = 1;
    // 마지막으로 받은 데이터
    lastResponse = null;

    constructor() {

    }

}