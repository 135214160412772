import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/views/NotFound'

Vue.use(Router)
Vue.use(Meta, {
  keyName: 'metaInfo', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-vue-meta', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'vmid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

// [20191001 KTH 추가] 버젼 관리에 대한 연구중
// 패치할 때마다 아래의 'ver'의 값과 'D:\work\3dbank\3dbank_3dworld\public\js\ver' 파일의 값을 동일한 값으로 증가시키면
// 유저들이 이미 실행시켜 놓은 앱일지라도 페이지 이동시 새 버젼으로 리로드되도록 유도하였다.
const checkVersion = {
  ver: 63,
  now: null,
  timer: null,
  run: function () {
    if (checkVersion.timer) clearTimeout(checkVersion.timer);
    checkVersion.timer = setTimeout(checkVersion.send, 3000);
  },
  send: function () {
    checkVersion.timer = null;
    checkVersion.now = Math.floor((new Date()).getTime() / 1000);

    let xmlhttp = new XMLHttpRequest();

    xmlhttp.open('GET', location.origin + '/js/ver?' + checkVersion.now);
    xmlhttp.addEventListener('load', checkVersion.loaded);
    xmlhttp.send();
  },
  loaded: function (e) {
    if (e.target.status == 200) {
      console.log('[checkVersion]\nthis ver.', checkVersion.ver, '( server ver.', e.target.responseText, ')');

      if (checkVersion.ver != e.target.responseText) {
        location.replace(location.origin + '/main?' + checkVersion.now);
      }
    }
  },
}

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = function (to, from, savedPosition) {
  checkVersion.run();

  console.log('[scrollBehavior]', '\nto', to, '\nfrom', from, '\nsavedPosition', savedPosition);

  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return new Promise(resolve => {
      setTimeout(function () {
        resolve(savedPosition);
      }, 10);

    });
  } else {
    const position = {}

    // scroll to anchor by returning the selector
    if (to.hash) {
      position.selector = to.hash

      // specify offset of the element
      if (to.hash === '#anchor2') {
        position.offset = {y: 100}
      }

      if (document.querySelector(to.hash)) {
        return position
      }

      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      return false
    }

    return new Promise(resolve => {
      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some(m => m.meta.scrollToTop)) {
        // coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.x = 0
        position.y = 0
      }

      // wait for the out transition to complete (if necessary)
      this.app.$root.$once('triggerScroll', () => {
        // if the resolved position is falsy or an empty object,
        // will retain current scroll position.
        console.log("triggerScroll", position);
        resolve(position)
      })
    })
  }
}


export default new Router({
  mode: 'history',
  scrollBehavior,
  routes: [
    {
      path: '/',
      name: 'Splash',
      component: () => import('@/views/Splash.vue')
    },
    {
      path: '/tutorial',
      name: 'tutorial',
      component: () => import('@/views/Tutorial.vue')
    },

    // --------- Main -----------
    {
      path: '/main',
      name: 'MainIndex',
      component: () => import('@/views/main/MainIndex.vue')
    },
    {
      path: '/main/:app_type',
      name: 'MainIndex_type',
      component: () => import('@/views/main/MainIndex.vue')
    },

    // --------- Content -----------
    {
      path: '/content/outside2d',
      name: 'Outside2D',
      component: () => import('@/views/content/Outside2D.vue'),
    },
    {
      path: '/content/list/:major_id',
      name: 'contentlist',
      component: () => import('@/views/content/ContentList.vue'),
    },
    {
      path: '/content/search/:major_id',
      name: 'contentSearch',
      component: () => import('@/views/content/ContentSearch.vue')
    },
    {
      path: '/content/filter',
      name: 'ContentFilter',
      component: () => import('@/views/common/FilterMain.vue'),
    },
    {
      path: '/content/:cont_id/detail',
      name: 'contentdetail',
      component: () => import('@/views/content/ContentDetail.vue'),
    },
    {
      path: '/content/:cont_id/comment/:type',
      name: 'contentcomment',
      component: () => import('@/views/content/ContentCommentWrite.vue'),
    },
    {
      path: '/content/:cont_id/outside2d',
      name: 'outside2d',
      component: () => import('@/views/content/Outside2DView.vue'),
    },
    // 젤 아래 있어야 한다.
    {
      path: '/content/:cont_id',
      name: 'contentview',
      component: () => import('@/views/content/ContentView.vue'),
    },
    // --------- Museum -----------
    {
      path: '/museum',
      name: 'museumIndex',
      component: () => import('@/views/museum/MuseumIndex.vue'),
    },
    {
      path: '/museum/age/:age_cd',
      name: 'museumAge',
      component: () => import('@/views/content/ContentList.vue'),
    },
    {
      path: '/museum/location/:priovince_tp',
      name: 'museumLocation',
      component: () => import('@/views/museum/MuseumLocation.vue')
    },
    {
      path: '/museum/:type/detail/:id',
      name: 'museumLocationDetail',
      component: () => import('@/views/museum/MuseumDetail.vue')
    },
    {
      path: '/museum/search',
      name: 'museumSearch',
      component: () => import('@/views/museum/MuseumSearch.vue')
    },


    // --------- Collection -----------
    {
      path: '/collection',
      name: 'collectionIndex',
      component: () => import('@/views/collection/CollectionIndex.vue')
    },
    {
      path: '/collection/detail/:cate_id',
      name: 'collectionDetail',
      component: () => import('@/views/collection/CollectionDetail.vue')
    },
    {
      path: '/collection/newCate',
      name: 'newCate',
      component: () => import('@/views/collection/NewCate.vue')
    },
    {
      path: '/collection/upload',
      name: 'upload',
      component: () => import('@/views/collection/CollectionUpload.vue')
    },
    {
      path: '/collection/edit/:id',
      name: 'edit',
      component: () => import('@/views/collection/CollectionUpload.vue')
    },
    {
      path: '/collection/search',
      name: 'collectionsearch',
      component: () => import('@/views/collection/Search.vue')
    },

    // --------- Hologram -----------
    {
      path: '/hologram/detail/:id',
      name: 'hologramDetail',
      component: () => import('@/views/hologram/HologramDetail.vue')
    },
    {
      path: '/hologram/detail/:id/user',
      name: 'hologramDetailUser',
      component: () => import('@/views/hologram/HologramDetailUser.vue')
    },
    {
      path: '/hologram/detail/:id/demo',
      name: 'hologramDetailDemo',
      component: () => import('@/views/hologram/HologramDetailDemo.vue')
    },
    {
      path: '/hologram/connect',
      name: 'hologramConnect',
      component: () => import('@/views/hologram/HologramConnect.vue')
    },
    {
      path: '/hologram/set_holo_mobile',
      name: 'SetHoloMobile',
      component: () => import('@/views/hologram/SetHoloMobile.vue'),
    },
    {
      path: '/hologram/set_holo_iot',
      name: 'SetHoloIot',
      component: () => import('@/views/hologram/SetHoloIot.vue'),
    },
    {
      path: '/hologram/:id',
      name: 'hologramIndex',
      component: () => import('@/views/hologram/HologramIndex.vue')
    },

    // --------- Common -----------
    {
      path: '/common/filter',
      name: 'filtermain',
      component: () => import('@/views/common/FilterMain.vue')
    },
    {
      path: '/common/changepw',
      name: 'changepw',
      component: () => import('@/views/common/ChangePassword.vue')
    },
    {
      path: '/common/delaccount',
      name: 'delaccount',
      component: () => import('@/views/common/DeleteAccount.vue')
    },

    {
      path: '/common/settinguser',
      name: 'settinguser',
      component: () => import('@/views/common/SettingUser.vue')
    },
    {
      path: '/common/setting',
      name: 'settingapp',
      component: () => import('@/views/common/SettingApp.vue')
    },
    {
      path: '/common/login',
      name: 'loginIndex',
      component: () => import('@/views/common/login/LoginIndex.vue')
    },
    {
      path: '/common/login/signup',
      name: 'loginSignup',
      component: () => import('@/views/common/login/Signup.vue')
    },
    {
      path: '/common/login/findpw',
      name: 'findPassword',
      component: () => import('@/views/common/login/FindPassword.vue')
    },
    {
      path: '/common/login/callback/naver',
      name: 'AuthNaverCallback',
      component: () => import('@/views/common/login/AuthNaverCallback.vue')
    },
    {
      path: '/common/login/callback/google',
      name: 'AuthGoogleCallback',
      component: () => import('@/views/common/login/AuthGoogleCallback.vue')
    },
    {
      path: '/oauth',
      name: 'AuthKakaoCallback',
      component: () => import('@/views/common/login/AuthKakaoCallback.vue')
    },
    {
      path: '*',
      component: NotFound
    }
  ]
});
