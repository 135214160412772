export class MuseumEntry {
    //지역별 박물관 
    list_location = new Array();
    //시대별 박물관
    list_age = new Array();
    // 박물관 메인 Tab Index
    tabIndex = 1;

    constructor() {

    }

}